import React, { useEffect, useState } from 'react';
import NotifyController from '../utilities/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import FeedbackService from '../service/FeedbackService';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { genTimePerJob } from '../utilities/util';
import { Dropdown } from 'primereact/dropdown';

const GeneratedImageDetail = () => {
    const [data, setData] = useState<any>();
    const [selectedTempalte, setSelectedTempalte] = useState<any>(null);
    const [group, setGroup] = useState<any>([]);

    const { id } = useParams();
    const navigate = useNavigate();

    const getImageDetailData = async () => {
        if (id) {
            try {
                const imageData = await FeedbackService.getInstance().getImageDetail(id);
                setData(imageData.data);
            } catch (error: any) {
                NotifyController.error(error?.message);
                console.log(error);
            }
        }
    };

    const getGroupData = async () => {
        try {
            const groupData = await FeedbackService.getInstance().getGroupData();

            setGroup(groupData.data.map((item: any, index: any) => {
                return {
                    name: item,
                    code: item
                }
            }));
        } catch (error: any) {
            NotifyController.error(error?.message);
            console.log(error);
        }
    };

    const addTemplate = async () => {
        try {
            if (selectedTempalte) {
                const confirmed = window.confirm("Are you sure you want to add template?");
                if (confirmed) {
                    await FeedbackService.getInstance().addTemplate({ group: selectedTempalte.name ? selectedTempalte.name : selectedTempalte, imageUrl: data.input.clothImage });
                    NotifyController.success('Add template success');
                }

            } else {
                NotifyController.error('Please select a category');
            }
        } catch (error: any) {
            NotifyController.error(error?.message);
            console.log(error);
        }
    }

    const statusColor: any = {
        FINISHED: 'green',
        FAILED: 'red',
        IN_QUEUE: 'yellow',
    };

    const calculateGenerationTime = () => {
        if (data && data.created_ts && data.finished_ts) {
            const genTimeInSeconds = Math.floor((data.finished_ts - data.created_ts) / 1000);
            const minutes = Math.floor(genTimeInSeconds / 60);
            const seconds = genTimeInSeconds % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }
        return '';
    };

    const renderModerationLabels = () => {
        if (data && data.moderation_labels && data.moderation_labels.length > 0) {
            return data.moderation_labels.map((label: any, index: number) => (
                <div key={index} style={{ fontWeight: 'bold' }}>
                    {label.Name}
                </div>
            ));
        }
        return '';
    };

    useEffect(() => {
        getImageDetailData();
        getGroupData();
    }, []);

    return (
        <div style={{ display: 'grid', gap: '8px', marginBottom: '16px' }}>
            <Button onClick={() => navigate(-1)} style={{ width: 70 }}>Go Back</Button>
            {data && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Image:</div>
                    <div>{data.is_blur ? <Image preview src={`${data.url_v2[0].replace('uploads/blur-', 'uploads/')}`} alt="Blurred Image" /> : <Image preview src={data.url_v2[0]} alt="Generated Image" />}</div>
                </div>
            )}
            {data && (
                <>
                    <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                        <div style={{ fontWeight: 'bold' }}>Origin Image:</div>
                        <div><Image preview src={`${data.input.modelImage}`} alt="Origin Image" /></div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                        <div style={{ fontWeight: 'bold' }}>Mask Image:</div>
                        <div><Image preview src={`${data.input.modelMaskImage}`} alt="Origin Image" /></div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                        <div style={{ fontWeight: 'bold' }}>Cloth Image:</div>
                        <div><Image preview src={`${data.input.clothImage}`} alt="Origin Image" /></div>
                        <Dropdown value={selectedTempalte} onChange={(e) => setSelectedTempalte(e.value)} options={group} optionLabel="name"
                            editable placeholder="Select category" className="w-full mr-3" />
                        <Button label="Add Image To Template" onClick={addTemplate} />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                        <div style={{ fontWeight: 'bold' }}>IP Controlnet Image:</div>
                        <div><Image preview src={`${data.input.ipcontrolnetImage}`} alt="Origin Image" /></div>
                    </div>
                </>
            )}
            {data && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Gen Time:</div>
                    <div>
                        <span>{calculateGenerationTime()}</span>
                    </div>
                </div>
            )}
            {data && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Queue Time per Job:</div>
                    <div>
                        <span>{genTimePerJob(data.sub_queue_time ?? [0])?.join("  ")}</span>
                    </div>
                </div>
            )}
            {data && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Gen Time per Job:</div>
                    <div>
                        <span>{genTimePerJob(data.sub_progress_time ?? [0])?.join("  ")}</span>
                    </div>
                </div>
            )}
            {data && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Status:</div>
                    <div>
                        {data.status && (
                            <span style={{ color: statusColor[data.status] }}>{data.status}</span>
                        )}
                    </div>
                </div>
            )}
            {data && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Progress:</div>
                    <div>
                        {data.progress && (
                            <span>{(data.progress * 100).toFixed(2)}%</span>
                        )}
                    </div>
                </div>
            )}
            {data && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Resolution:</div>
                    <div>
                        {data.width && data.height && (
                            <span> {data.width}x{data.height}</span>
                        )}
                    </div>
                </div>
            )}
            {data && data.moderation_labels && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Moderation Labels:</div>
                    <div>
                        {renderModerationLabels()}
                    </div>
                </div>
            )}
            {data && data.response && (
                <div style={{ display: 'grid', gridTemplateColumns: '3fr 9fr' }}>
                    <div style={{ fontWeight: 'bold' }}>Response:</div>
                    <div style={{ maxWidth: '800px', overflowX: 'auto' }}>
                        <pre>{JSON.stringify(JSON.parse(data.response), null, 2)}</pre>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GeneratedImageDetail;
