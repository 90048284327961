import { Column } from 'primereact/column';
import { CustomDataTable, tableOptions } from '../components/CustomDatatable';
import NotifyController from '../utilities/Toast';
import FeedbackService from '../service/FeedbackService';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';

const IAPNotification = () => {
    const navigate = useNavigate();
    const { page } = useParams();
    const [refresh, setRefresh] = useState(false);

    const onOptionChange = async (option: tableOptions) => {
        const feedbacks = await FeedbackService.getInstance().getListIAPNotify(
            option.page,
            option.rowPerPage,
            option.orderType,
            option.order,
            option.filter
        ).catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        return { total: feedbacks.data.totalRecord, data: feedbacks.data.items }
    }

    const bodyPrice = (rowData: any) => {
        return (
            <div>
                {rowData.price / 1000}
            </div>
        )
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        if (!timestamp) return <span></span>;

        // Convert the timestamp using moment and adjust for GMT+7 timezone
        const formattedDateTime = moment(timestamp)
            .utcOffset(7) // Set offset for GMT+7
            .format('YYYY-MM-DD HH:mm:ss'); // Format the date as 'yyyy-MM-dd HH:mm:ss'

        return <span>{formattedDateTime}</span>;
    };
    const handlePageChange = (page: any) => {
        navigate(`/iap-notification/${page}`)
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Generated Image</h5>
                    <CustomDataTable
                        onOptionChange={onOptionChange}
                        page={parseInt(page ?? '1')}
                        handlePageChange={handlePageChange}
                        refresh={refresh}
                    >
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Created" headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="type" header="Type" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="originalTransactionId" header="Original Trans Id" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="transactionId" header="Trans Id" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="productId" header="Product Id" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="price" header="Price" body={bodyPrice} sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="currency" header="Currency" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="notificationType" header="Notify Type" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="subtype" header="Sub Type" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="environment" header="Env" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="status" header="Status" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                    </CustomDataTable>
                </div>
            </div>
        </div>
    );
};

export default IAPNotification;
